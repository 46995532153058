<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div class="
          col-7
          p-0
          align-self-center
          font-weight-bold
          d-flex
          align-items-center
          w-reset
          mb-sm-2
        ">
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.report_partner_clinics") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input size="mini" :placeholder="$t('message.search')" prefix-icon="el-icon-search"
            v-model="filterForm.search" clearable></el-input>
          <el-date-picker size="mini" v-model="filterForm.orders_from" type="date" :format="'dd.MM.yyyy'"
            :value-format="'yyyy-MM-dd'" :placeholder="$t('message.start_date')"></el-date-picker>
          <el-date-picker size="mini" v-model="filterForm.orders_to" type="date" :format="'dd.MM.yyyy'"
            :value-format="'yyyy-MM-dd'" :placeholder="$t('message.end_date')"></el-date-picker>
        </div>
      </div>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination :pagination="pagination" @c-change="updatePagination"></crm-pagination>
      <thead>
        <tr>
          <crm-th :column="columns.id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.name" :sort="sort" @c-change="updateSort"></crm-th>
          <!-- <crm-th
            :column="columns.total_price"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th> -->
          <crm-th :column="columns.bonus_amount" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.bonus_paid_amount" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.bonus_debt" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.order_count" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :sort="sort" :column="columns.updated_at" @c-change="updateSort"></crm-th>
          <crm-th :sort="sort" :column="columns.created_at" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.settings" :sort="sort" @c-change="updateSort">{{ columns.settings.title }}</crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input clearable size="mini" class="id_input" v-model="filterForm.id"
              :placeholder="columns.id.title"></el-input>
          </th>

          <th v-if="columns.name.show">
            <el-input size="mini" v-model="filterForm.name" :placeholder="columns.name.title" clearable>
            </el-input>
          </th>

          <!-- <th v-if="columns.total_price.show">
            <el-input
              size="mini"
              v-model="filterForm.total_price"
              :placeholder="columns.total_price.title"
              clearable
            >
            </el-input>
          </th> -->

          <th v-if="columns.bonus_amount.show">
            <el-input size="mini" v-model="filterForm.bonus_amount" :placeholder="columns.bonus_amount.title" clearable>
            </el-input>
          </th>

          <th v-if="columns.bonus_paid_amount.show">
            <el-input size="mini" v-model="filterForm.bonus_paid_amount" :placeholder="columns.bonus_paid_amount.title"
              clearable>
            </el-input>
          </th>

          <th v-if="columns.bonus_debt.show">
            <el-input size="mini" v-model="filterForm.bonus_debt" :placeholder="columns.bonus_debt.title" clearable>
            </el-input>
          </th>

          <th v-if="columns.order_count.show">
            <el-input size="mini" v-model="filterForm.order_count" :placeholder="columns.order_count.title" clearable>
            </el-input>
          </th>

          <th v-if="columns.updated_at.show">
            <el-date-picker :format="'dd.MM.yyyy'" :value-format="'dd.MM.yyyy'" v-model="filterForm.updated_at"
              :placeholder="columns.updated_at.title" size="mini">
            </el-date-picker>
          </th>
          <th v-if="columns.created_at.show">
            <el-date-picker :format="'dd.MM.yyyy'" :value-format="'dd.MM.yyyy'" v-model="filterForm.created_at"
              :placeholder="columns.created_at.title" size="mini">
            </el-date-picker>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr v-for="(partnerDoctorReport, index) in list" :key="partnerDoctorReport.id">
          <td v-if="columns.id.show">{{ index + 1 }}</td>
          <td v-if="columns.name.show">{{ partnerDoctorReport.bonusable.name }}</td>
          <!-- <td v-if="columns.total_price.show">
            {{ partnerDoctorReport.total_price }}
          </td> -->
          <td v-if="columns.bonus_amount.show">
            {{ partnerDoctorReport.bonus_amount | formatMoney }}
          </td>
          <td v-if="columns.bonus_paid_amount.show">
            {{ partnerDoctorReport.paid_amount | formatMoney }}
          </td>
          <td v-if="columns.bonus_debt.show">
            {{
              (partnerDoctorReport.bonus_amount - partnerDoctorReport.paid_amount)
              | formatMoney
            }}
          </td>
          <td v-if="columns.order_count.show">
            {{ partnerDoctorReport.order_count }}
          </td>
          <td v-if="columns.updated_at.show">
            {{ partnerDoctorReport.updated_at }}
          </td>
          <td v-if="columns.created_at.show">
            {{ partnerDoctorReport.created_at }}
          </td>
          <td v-if="columns.settings.show" class="settings-td">
            <el-button @click="showReport(partnerDoctorReport)" type="success" icon="el-icon-view" round>{{
              $t("message.reports") }}</el-button>
          </td>
        </tr>
      </transition-group>
    </table>

    <!-- <el-drawer  :visible.sync="drawerCreate" size="60%" :drawer="drawerCreate" @close="reloadIfChanged" class="mytab_IdP">
        <div>
            <crm-create :reloadModel="reopenUpdate" @c-close="reloadIfChanged('drawerCreate')"></crm-create>
        </div>
    </el-drawer>
    <el-drawer  :visible.sync="drawerUpdate" size="60%" :drawer="drawerUpdate"
        @open="reopenUpdate = true" @close="reloadIfChanged('reopenUpdate')" @closed="emptyModel" class="mytab_IdP">
        <div>
            <crm-update :reloadModel="reopenUpdate" @open="reopenUpdate = true" @c-close="closeDrawer"></crm-update>
        </div>
    </el-drawer> -->
    <el-drawer :visible.sync="drawerCreate" size="60%" :wrapperClosable="false" :drawer="drawerCreate"
      @close="reloadIfChanged" class="mytab_IdP">
      <div>
        <crm-create :reloadModel="reopenUpdate" @c-close="reloadIfChanged('drawerCreate')"></crm-create>
      </div>
    </el-drawer>
    <el-drawer :visible.sync="drawerUpdate" size="60%" :wrapperClosable="false" :drawer="drawerUpdate"
      @open="reopenUpdate = true" @close="reloadIfChanged('reopenUpdate')" @closed="emptyModel" class="mytab_IdP">
      <div>
        <crm-update :reloadModel="reopenUpdate" @open="reopenUpdate = true" @c-close="closeDrawer"></crm-update>
      </div>
    </el-drawer>

    <el-drawer class="popups_title" size="90%" :wrapperClosable="false" :visible.sync="drawer"
      @opened="drawerOpened('cabinet_doctor')" :with-header="false">
      <CabintPartnerDoctor @c-close="drawer = false" :selected="selectedModel" ref="cabinet_doctor">
      </CabintPartnerDoctor>
    </el-drawer>
  </div>
</template>

<script>
import CabintPartnerDoctor from "./components/cabnet-partnerdoctor";
import { mapGetters, mapActions, mapState } from "vuex";
import list from "@/utils/mixins/list";
export default {
  mixins: [list],
  name: "partnerDoctorReportList",

  data() {
    return {
      drawerCreate: false,
      drawerUpdate: false,
      reopenUpdate: false,
      // excel_data: [],
      loadingData: false,
      drawer: false,
      selectedModel: {},
    };
  },
  components: {
    CabintPartnerDoctor,
  },
  computed: {
    ...mapGetters({
      list: "partnerClinicReport/list",
      columns: "partnerClinicReport/columns",
      pagination: "partnerClinicReport/pagination",
      filter: "partnerClinicReport/filter",
      sort: "partnerClinicReport/sort",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() { },

  watch: {},
  methods: {
    ...mapActions({
      showModel: "partnerClinicReport/show",
      updateList: "partnerClinicReport/index",
      updateSort: "partnerClinicReport/updateSort",
      updateFilter: "partnerClinicReport/updateFilter",
      updateColumn: "partnerClinicReport/updateColumn",
      updatePagination: "partnerClinicReport/updatePagination",
      editModel: "partnerClinicReport/show",
      empty: "partnerClinicReport/empty",
      delete: "partnerClinicReport/destroy",
      refreshData: "partnerClinicReport/refreshData",
    }),
    fetchData() {
      this.loadingData = true;
      const query = { ...this.filter, ...this.pagination, ...this.sort };

      this.updateList(query)
        .then((res) => {
          this.partnerDoctorReport = res.partnerDoctorReport;
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        });
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => { });
    },
    async edit(model) {
      await this.editModel(model.id)
        .then(async (res) => {
          this.drawerUpdate = true;
        })
        .catch((err) => { });
    },
    async show(model) {
      await this.showModel(model.id)
        .then((res) => {
          this.drawerShow = true;
        })
        .catch((err) => { });
    },
    drawerOpened(ref) {
      this.$refs[ref].afterOpen();
    },
    showReport(model) {
      this.drawer = true;
      this.selectedModel = model;
      // this.$loadingCursor("wait");
      // this.showModel({ id: model.bonusable_id })
      //   .then((res) => {
      //     this.$loadingCursor("default");
      //   })
      //   .catch((err) => {
      //     this.$notify({
      //   title: "Ошибка",
      //   type: "error",
      //   offset: 130,
      //   message: err.error.message,
      // });
      //     this.$loadingCursor("default");
      //     this.drawer = false;
      //   });
    },
    handleCurrentChange(val) {
      this.updatePagination({ key: "page", value: val })
        .then((res) => { })
        .catch((err) => { });
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },

    destroyModel(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: 'Ошибка',
            type: "error",
            offset: 130,
            message: res.message
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
